import { action, json, reload, revalidate } from "@solidjs/router";
import { _updateItemInCart } from "../roma-api/cart";
import { retrieveCart } from "./useCart";
import { OrderProductTypes } from "../roma-api/cart/types-productOrdering";

export const editCartItemAction = action(
  async (item_id: string, item: OrderProductTypes, cart_id?: string) => {
    "use server";

    const response = await _updateItemInCart(item_id, item, cart_id);
    // TODO deal w errors

    await revalidate(retrieveCart.key);
    // return reload({ revalidate: retrieveCart.key });
    return json(response);

    // PREV WAS: 
    // return await revalidate(retrieveCart.key)
  }
);
