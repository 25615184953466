import { getSession } from "~/services/session";
import { json } from "@solidjs/router";
import type { CartDetail } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { APIError, simulateApiError } from "../errors";

export const _retrieveCart = async (
  id: string = "current"
): Promise<CartDetail> => {
  try {
    const session = await getSession();
    if (!session?.token) {
      if (import.meta.env.DEV) {
        console.log("[retrieveCart.ts]: No session/token found");
      }
      // @ts-expect-error
      // TODO
      return;
    }

    // await simulateApiError({
    //       path: `/cart/${id}?plant=true`,
    //       code: "INVALID_CART",
    //       message: "Cart status is deleted.",
    //       statusCode: 410,
    //     })

    const data = await fetchAPI2<CartDetail>(`/cart/${id}?plant=true`);
    // const data = await fetchAPI2<CartDetail>(`/cart/${id+"123"}?plant=true`);

    // TODO - handle cart STATUS - deleted? submitted?.
    return data;
  } catch (error) {
    // TODO Error Handling
    if (import.meta.env.DEV) {
      console.log("[retrieveCart.ts]: error caught:", error);
    }
    throw error;
  }
};
