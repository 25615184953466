import { action, reload, revalidate } from "@solidjs/router";
import { _deleteItemFromCart } from "../roma-api/cart";
import { retrieveCart } from "./useCart";

export const removeFromCartAction = action(
  async (item_id: string, cart_id?: string) => {
    "use server";

    const response = await _deleteItemFromCart(item_id, cart_id);
    // TODO deal w errors
    console.log(
      response
    );

    return await revalidate(retrieveCart.key);
  }
);
