import type { OrderAddress } from "../admin/orders";
import type { OrderDetail } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _sendCheckout = async (options: SendCheckoutOptions) => {
  const { id = "current" } = options;

  // await simulateApiError({ path: `/cart/${id}x/checkout`, message: "Could not submit cart", code: "INTERNAL_ERROR" });

  const data = await fetchAPI2<SendCheckoutResponse, CheckoutPayload>(
    `/cart/${id}/checkout`,
    {
      method: "POST",
      body: options.details,
    }
  );

  return data;
};

export type SendCheckoutResponse = {
  CurrentCartID: string; // next current cart if multiple carts exist, or new cart (?)
  ID: string; // the cart that was submitted;
  CustomerNumber: string; //why? this is not present on submitted cart
  Status: string; // should be "submitted"
  OrderNumbers: OrderDetail[];
};

export type SendCheckoutOptions = {
  id?: string;
  details: CheckoutPayload;
};

export type CheckoutPayload = {
  ShipTo: string; // AccountID
  ShipCustom?: boolean;
  Pickup?: boolean;
  PONumber?: string;
  InternalNotes?: string; // do we ever set this in front end? // NOPE
  ExternalNotes?: string;
  ShipToDetail?: Optional<OrderAddress, "Street2">;
  CustomerNumber?: string; // Only present on BPS orders
  ProcessDate?: string; // Only present if user chooses to delay shipping
};
