import { action, reload, revalidate, json } from "@solidjs/router";
import { _sendItemToCart } from "../roma-api/cart";
import { retrieveCart } from "./useCart";
import { OrderProductTypes } from "../roma-api/cart/types-productOrdering";

export const addToCartAction = action(async (item: OrderProductTypes | OrderProductTypes[], cart_id?: string) => {
  "use server";

  const response = await _sendItemToCart(item, cart_id);
  // TODO deal w errors
  
  await revalidate(retrieveCart.key);
  return  json(response)
  // ! 1st BELOW ORIGINAL
  // return  json(response, {revalidate: retrieveCart.key})
  // return await revalidate(retrieveCart.key)
});
