import { action } from "@solidjs/router";
import {
  _sendCheckout,
  type SendCheckoutOptions,
  type CheckoutPayload,
} from "../roma-api/cart";

export const submitCheckoutAction = action(async (options: SendCheckoutOptions) => {
  const response = await _sendCheckout(options);

  console.log("response from _sendCheckout in submitCheckoutAction() : ", response);
  return response
  // What needs to be revalidated here? 
});
